@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

@font-face {
  font-family: Satoshi;
  src: local("Satoshi"), url("assets/fonts/Satoshi-Variable.ttf");
}

.scrollbar-gutter-stable {
  scrollbar-gutter: stable;
}

.drawer-overlay {
  opacity: 0;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  transition-property: opacity;
  transition-duration: 1s;
}

.drawer-container {
  position: fixed;
  visibility: hidden;
  transition: all;
}

.drawer-opened {
  visibility: visible;
  transform: translate3d(0, 0, 0) !important;
}
